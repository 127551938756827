import React, {useEffect, useState} from "react";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {Autocomplete, Button, Card, Grid, TextField} from "@mui/material";
import CustomTextField from "../Fields/CustomTextField";
import {useTranslation} from "react-i18next";
import Stack from "@mui/material/Stack";
import {useSelector} from "react-redux";
import CustomTextFieldAutocomplete from "../Fields/CustomTextFieldAutocomplete";
import {$authHost} from "../../http";

const EducationEditForm = ({handleClose, loadData}) => {
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation()

  const methods = useForm({
    mode: 'onBlur',
  });

  const {
    manufacturers,
    selectedManufacturers,
    questionsAndAnswers,
    selectedQuestionAndAnswer,
    selectedIntellegence
  } = useSelector(state => state.intelligensReducer)

  const {
    register,
    formState: {errors, isDirty, isSubmitSuccessful},
    handleSubmit,
    control,
    reset,
    setValue
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);

    var new_data = {
      id: selectedQuestionAndAnswer.id,
      question: data.question,
      answer: data.answer,
      assistant_type: data.assistant_type,
      assistant_id:
          selectedIntellegence.breaker_assistant.type === data.assistant_type ?
              selectedIntellegence.breaker_assistant.id
              :
              selectedIntellegence.output_assistant.id,
      is_run: selectedQuestionAndAnswer.is_run
    }
    console.log(new_data);
    try {
      await $authHost.patch(`/model_management/update_one`, new_data).then((response)=> {
        if(response.status === 201){
          handleClose();
          loadData();
        }
      })
    } catch (e) {
    } finally {
      setLoading(false)
    }
  };

  const cleanChanges = () => {
    setValue('question', selectedQuestionAndAnswer?.question)
    setValue('answer', selectedQuestionAndAnswer?.answer)
    setValue('assistant_type', selectedQuestionAndAnswer?.assistant_type)
  }

  useEffect(() => {
    cleanChanges()
  }, [])

  useEffect(() => {
    cleanChanges()
  }, [selectedQuestionAndAnswer])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card sx={{p: 3, mb: 2}}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                direction='column'
                justifyContent='space-between'
                alignItems='flex-start'
              >
                <CustomTextField
                    name="question"
                    label={ t('src.pages.ModelManagement.question')}
                    registerOptions={{
                      required: t('main.another.requiredField'),
                    }}
                    required
                    sx={{width: '100%', fontSize: '12px', mb: 3}}
                    size="small"
                />
                <CustomTextField
                    name="answer"
                    label= {t('src.pages.ModelManagement.answer')}
                    registerOptions={{
                      required: t('main.another.requiredField'),
                    }}
                    required
                    sx={{width: '100%', fontSize: '12px', mb: 3}}
                    size="small"
                />
                <CustomTextFieldAutocomplete
                    name="assistant_type"
                    label={ t('src.pages.ModelManagement.assistant_type')}
                    options={[
                      { name:t('src.pages.Intelligence.breaker'), value: "breaker" },
                      { name:t('src.pages.Intelligence.stylist'), value: "output" },
                    ]}
                    rules={{
                      required: t('main.another.requiredField'),
                    }}
                    sx={{ width: '100%', fontSize: '12px', mb: 3 }}
                    size="small"
                />
              </Grid>
              <Grid item sx={{width: '100%', mb: 2}}>
                <Stack
                  direction='row'
                  spacing={1}
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Grid item>
                    <Button
                      variant='contained'
                      type='submit'
                      sx={{mr: 2}}
                      disabled={loading}
                    >
                      {t('main.another.save')}
                    </Button>
                    <Button variant='text' onClick={handleClose}>
                      {t('main.another.close')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='text'
                      onClick={cleanChanges}
                      disabled={loading}
                    >
                      {t('main.another.clear')}
                    </Button>
                  </Grid>
                </Stack>
              </Grid>
            </form>
          </FormProvider>
        </Card>
      </Grid>
    </Grid>
  );
};

export default EducationEditForm;