import React, {useCallback, useEffect, useState} from 'react';
import {Button, Card, Grid, IconButton, Typography} from "@mui/material";
import {
  DndProvider,
  getBackendOptions,
  getDescendants,
  isAncestor,
  MultiBackend,
  Tree,
} from "@minoru/react-dnd-treeview";
import styles from "./DnDTree/App.module.css";
import {NativeTypes} from "react-dnd-html5-backend";
import {CustomNode} from "./DnDTree/CustomNode";
import {MultipleDragPreview} from "./DnDTree/MultipleDragPreview";
import {CustomDragPreview} from "./DnDTree/CustomDragPreview";
import {useActions} from "../hook/useActions";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {$authHost} from "../http";
import UnfoldMoreDoubleIcon from "@mui/icons-material/UnfoldMoreDouble";
import UnfoldLessDoubleIcon from "@mui/icons-material/UnfoldLessDouble";
import {acceptFileTypeBot, acceptFileTypeXL} from "../constants";
import {chekMaxSize} from "../utils/formatSize";
import useResponsive from "../hook/useResponsive";
import {ArrowRight} from "@mui/icons-material";
import {ClockIcon} from "@mui/x-date-pickers";
import CloseIcon from "@mui/icons-material/Close";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

const FileKnowledgesTree = ({...props}) => {

  const {t} = useTranslation()
  const [isDragging, setIsDragging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editedNodeId, setEditedNodeId] = useState(null);
  const isMobile = useResponsive('down', 'md')


  const {
    setSelectedNodes,
    setIsCtrlPressing,
    setChosenNodes,
    setErrorAlertMessage,
    setSuccessAlertMessage,
    setIsMoveMode,
    setMoveNode
  } = useActions()

  const {
    selectedNodes,
    tree,
    isCtrlPressing,
    chosenNodes,
    isMoveMode
  } = useSelector(state => state.docsReducer)

  const {
    selectedIntellegence,
  } = useSelector(state => state.intelligensReducer)


  const handleDragStart = (node) => {
    const isSelectedNode = selectedNodes.some((n) => n.id === node.id);
    setIsDragging(true);

    if (!isCtrlPressing && isSelectedNode) {
      return;
    }

    if (!isCtrlPressing) {
      setSelectedNodes(CleanNodes([node]));
      return;
    }

    if (!selectedNodes.some((n) => n.id === node.id)) {
      setSelectedNodes(CleanNodes([...selectedNodes, node]));
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    setIsCtrlPressing(false);
    setSelectedNodes([]);
  };

  const handleSingleSelect = (node) => {
    const selectedIds = selectedNodes.map((n) => n.id);
    if (selectedIds.includes(node.id)) {
      setSelectedNodes([]);
    } else {
      setSelectedNodes([node]);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key.toLowerCase() === "escape") {
        setSelectedNodes([]);
      } else if (e.ctrlKey || e.metaKey) {
        setIsCtrlPressing(true);
      }
    };

    const handleKeyUp = (e) => {
      if (e.key.toLowerCase() === "control" || e.key.toLowerCase() === "meta") {
        setIsCtrlPressing(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const handleMultiSelect = (clickedNode) => {

    const selectedIds = selectedNodes.map((n) => n.id);

    // ignore if the clicked node is already selected
    if (selectedIds.includes(clickedNode.id)) {
      handleUnSelect(clickedNode);
      return;
    }

    // ignore if ancestor node already selected
    if (
      selectedIds.some((selectedId) =>
        isAncestor(tree, selectedId, clickedNode.id)
      )
    ) {
      return;
    }

    let updateNodes = [...selectedNodes];

    // if descendant nodes already selected, remove them
    updateNodes = updateNodes.filter((selectedNode) => {
      return !isAncestor(tree, clickedNode.id, selectedNode.id);
    });

    updateNodes = [...updateNodes, clickedNode];
    setSelectedNodes(updateNodes);
  };

  const handleClick = (e, node) => {
    if (node.droppable === false) {
      if (e.ctrlKey || e.metaKey) {
        handleMultiSelect(node);
      } else {
        handleSingleSelect(node);
      }
    }
  };

  function CleanNodes(incom_node) {
    let nodes = [];
    incom_node.map((node, index) => {
      nodes.push(
        {
          id: node.id,
          name: node.name,
          description: node.description,
          parent: node.parent,
          droppable: node.droppable,
          size: node.size,
          created_at: node.created_at
        }
      )
    })
    return nodes;
  }

  const handleUnSelect = (node) => {
    let updateNodes = [...selectedNodes].filter((n) => n.id !== node.id);
    setSelectedNodes(updateNodes);
  };

  const handleChoice = async (node) => {
    setEditedNodeId(node.id)
    setLoading(true)
    const item = chosenNodes.find((n) => n.id === node.id);
    const selectedNode = [
      node,
      ...getDescendants(tree, node.id)
    ];
    if (!item) {
      try {
        await $authHost.post(`/model_management/study_file/change_files_in_assistant?intellect_id=${selectedIntellegence?.id}&file_id=${node.id}&openai_id=${selectedIntellegence?.main_assistant.openai_id}&command=add`,
        ).then((response) => {
          if (response.status === 201) {
            selectedNode.map((node) => {
              setChosenNodes([...chosenNodes, node]);
            })
          }
        })
      } catch (e) {
      } finally {
        setLoading(false)
      }
    } else {
      try {
        await $authHost.post(`/model_management/study_file/change_files_in_assistant?intellect_id=${selectedIntellegence?.id}&file_id=${node.id}&openai_id=${selectedIntellegence?.main_assistant.openai_id}&command=remove`,
        ).then((response) => {
          if (response.status === 201) {
            selectedNode.map((node) => {
              setChosenNodes(chosenNodes.filter((n) => n.id !== node.id));
            })
          }
        })
      } catch (e) {
      } finally {
        setLoading(false)
      }
    }
  };


  const handleDrop = (newTree, options) => {
    const {dropTargetId, monitor} = options;
    const itemType = monitor.getItemType();
    if (itemType === NativeTypes.FILE) {
      const files = monitor.getItem().files;
      files.map((file, index) => {
        if (acceptFileTypeBot.indexOf(file.type) !== -1) {
          if (chekMaxSize(file.size)) {
            let bodyFormData = new FormData()
            bodyFormData.append('file', file)
            props.addFile(bodyFormData, file, dropTargetId)
          } else {
            setErrorAlertMessage(t('src.components.Intelligence.limitationsSize'))
          }
        } else {
          setErrorAlertMessage(t('src.components.Intelligence.limitationsFile'))
        }
      });
    } else {
      selectedNodes.map((node) => {
        // console.log("node", node)
        let data = {
          id: node.id,
          name: node.name,
          description: node.description,
          droppable: node.droppable,
          parent: dropTargetId,
          size: node.size,
          created_at: node.created_at
        }
        // console.log(data)
        handleChangeNode({id: null, name: null, description: null, node: data})
      })
      setSelectedNodes([]);

    }
  };

  const handleChangeNode = async (data) => {
    const {id, name, description, node} = data
    let newData = {
      id: id === null ? node.id : id,
      name: name === null ? node.name : name,
      description: description === null ? node.description : description,
      droppable: node.droppable,
      created_at: node.created_at,
      size: node.size,
      parent: node.parent
    }
    try {
      await $authHost.patch(`/model_management/study_file/update`, newData).then(response => {
        if (response.status === 201) {
          props.loadMainData(selectedIntellegence?.id)
          props.loadData()

        }
      })
    } catch (e) {
    }
  };

  const handleDelete = async (id) => {
    const deleteIds = [
      id,
      ...getDescendants(tree, id).map((node) => node.id)
    ];
    deleteIds.map(async (node) => {
      try {
        await $authHost.delete(`/model_management/study_file/delete?id=${node}`).then(response => {
          if (response.status === 200) {
            props.loadData()
          }
        })
      } catch (e) {
      }
    })

  };

  const handleOpenAll = () => props.refTree.current?.openAll();
  const handleCloseAll = () => props.refTree.current?.closeAll();

  const updateData = async () => {
    let chData = chosenNodes.map(a => a.id);
    // console.log("chosenNodes ", chosenNodes)
    // console.log(chData)
    var update_data = {
      id: selectedIntellegence?.id,
      name: selectedIntellegence?.name,
      channels: selectedIntellegence?.channels,
      breaker_assistant: {
        id: selectedIntellegence?.breaker_assistant.id,
        type: "breaker",
        prompt: selectedIntellegence?.breaker_assistant.prompt,
        language: selectedIntellegence?.breaker_assistant.language,
        files: [],
        is_run: selectedIntellegence?.breaker_assistant.is_run
      },
      main_assistant: {
        id: selectedIntellegence?.main_assistant.id,
        type: "main",
        prompt: selectedIntellegence?.main_assistant.prompt,
        language: selectedIntellegence?.main_assistant.language,
        files: chData,
        is_run: selectedIntellegence?.main_assistant.is_run
      },
      output_assistant: {
        id: selectedIntellegence?.output_assistant.id,
        type: "output",
        prompt: selectedIntellegence?.output_assistant.prompt,
        language: selectedIntellegence?.output_assistant.language,
        files: [],
        is_run: selectedIntellegence?.output_assistant.is_run
      },
      bouncer_assistant: {
        id: selectedIntellegence?.bouncer_assistant.id,
        type: "bouncer",
        prompt: selectedIntellegence?.bouncer_assistant.prompt,
        language: selectedIntellegence?.bouncer_assistant.language,
        files: [],
        is_run: selectedIntellegence?.bouncer_assistant.is_run
      },
      is_run: false
    }
    // try {
    //   await $authHost.patch(`/intellect/update_one`, update_data).then((response) => {
    //     if (response.status === 200) {
    //     }
    //   })
    // } catch (e) {
    // } finally {
    // }

  }

  // useEffect(() => {
  //   if (props.visualChoice) {
  //     updateData()
  //   }
  // }, [chosenNodes])

  useEffect(() => {
    props.loadMainData(selectedIntellegence?.id)
    props.loadData()
  }, [])

  return (
    <Card
      sx={{p: {xs: 1, md:2, lg:3, xl:3}}}
    >
      {/*<Grid*/}
      {/*    container*/}
      {/*    direction="row"*/}
      {/*    sx={{mb:2}}*/}
      {/*>*/}
      {/*    <IconButton*/}
      {/*        variant="outlined"*/}
      {/*        color="primary"*/}
      {/*        onClick={handleOpenAll}*/}
      {/*        data-testid="btn-open-all"*/}
      {/*        sx={{mr:2, color: 'layout.accent_element'}}*/}
      {/*    >*/}
      {/*        <UnfoldMoreDoubleIcon/>*/}
      {/*    </IconButton>*/}
      {/*    <IconButton*/}
      {/*        variant="outlined"*/}
      {/*        color="primary"*/}
      {/*        onClick={handleCloseAll}*/}
      {/*        data-testid="btn-close-all"*/}
      {/*        sx={{color: 'layout.accent_element'}}*/}
      {/*    >*/}
      {/*        <UnfoldLessDoubleIcon/>*/}
      {/*    </IconButton>*/}
      {/*</Grid>*/}

      {isMobile?
          <>
            {isMoveMode?
                <Grid
                    container
                    direction="row"
                    sx={{
                      mt: 1,
                      mb: 1
                    }}
                >
                  <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div>
                      <IconButton size="small" onClick={
                        ()=>{
                          setIsMoveMode(false);
                          setMoveNode(null);
                        }
                      }
                      >
                        <CloseIcon sx={{fontSize: '20px'}}/>
                      </IconButton>
                      <Typography variant="headerTable" color="text.secondary">
                        {t('src.pages.KnowledgeTree.ChooseCategory')}
                      </Typography>
                    </div>
                    <div>
                      <IconButton size="small" onClick={props.addFolder}
                      >
                        <CreateNewFolderIcon sx={{fontSize: '20px'}}/>
                      </IconButton>
                    </div>

                  </Grid>
                </Grid>
                :
              <Grid
                  container
                  direction="row"
                  sx={{
                    mt: 1,
                    mb: 1
                  }}
              >
                <Grid item xs={9}>
                  <Typography variant="headerTable" color="text.secondary" sx={{marginLeft: '24px'}}>
                    {t('src.pages.KnowledgeTree.CategoryFile')}
                  </Typography>
                </Grid>
              </Grid>
            }
          </>
          :
          <Grid
              container
              direction="row"
              sx={{mt: 1, mb: 2}}
          >
            <Grid item xs={4}>
              <Typography variant="headerTable" color="text.secondary" sx={{marginLeft: '24px'}}>
                {t('src.pages.KnowledgeTree.CategoryFile')}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="headerTable" color="text.secondary">
                {t('src.pages.KnowledgeTree.Description')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="headerTable" color="text.secondary">
                {t('src.pages.KnowledgeTree.Size')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="headerTable" color="text.secondary">
                {t('src.pages.KnowledgeTree.CreatedDate')}
              </Typography>
            </Grid>
            <Grid item xs={1}>
            </Grid>
          </Grid>
      }
      <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        <div className={styles.storyRoot}>
          <Tree
            ref={props.refTree}
            rootId={0}
            tree={tree}
            extraAcceptTypes={[NativeTypes.FILE]}
            classes={{
              root: styles.treeRoot,
              draggingSource: styles.draggingSource,
              dropTarget: styles.dropTarget
            }}
            onDrop={handleDrop}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            canDrop={(tree, options) => {
              if (props.canDrop) {
                if (
                  selectedNodes.some(
                    (selectedNode) => selectedNode.id === options.dropTargetId
                  )
                ) {
                  return false;
                }
              } else
                return false;
            }}
            canDrag={(node) => {
              if (!isMobile && props.canDrag) {
                return node.droppable !== true;
              } else
                return false;
            }}
            render={(node, options) => {
              let selected;
              if (props.visualChoice) {
                selected = chosenNodes.some(
                  (selectedNode) => selectedNode.id === node.id
                );
              } else {
                selected = selectedNodes.some(
                  (selectedNode) => selectedNode.id === node.id
                );
              }
              return (
                <CustomNode
                  node={node}
                  {...options}
                  isSelected={selected}
                  isDragging={selected && isDragging}
                  onClick={handleClick}
                  onDelete={handleDelete}
                  // onCopy={handleCopy}
                  onChangeNode={handleChangeNode}
                  visualChoice={props.visualChoice}
                  onSelect={handleChoice}
                  Queryloading={loading}
                  EditedNodeId={editedNodeId}
                />
              );
            }}
            dragPreviewRender={(monitorProps) => {
              if (selectedNodes.length > 1) {
                return <MultipleDragPreview dragSources={selectedNodes}/>;
              }
              return <CustomDragPreview monitorProps={monitorProps}/>;
            }}

          />
        </div>
      </DndProvider>
    </Card>
  );
};

export default FileKnowledgesTree;