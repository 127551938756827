import React, {useContext} from 'react';
import {Switch} from "@mui/material";
import {useSelector} from "react-redux";
import {useActions} from "../hook/useActions";
import {useTranslation} from "react-i18next";
import FormControlLabel from "@mui/material/FormControlLabel";


const ThemeSwitch = () => {

    const { isDarkTheme } = useSelector(state => state.serviceReducer)
    const {t, i18n} = useTranslation();
    const { setIsDarkTheme } = useActions()

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={isDarkTheme}
                    onChange={(event, checked)=>setIsDarkTheme(checked) }
                />
            }
            label={`${t('src.layouts.dashboard.header.AccountPopover.darkTheme')}`}
        />

    );
};

export default ThemeSwitch;