import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Autocomplete, Button, Card, Container, Stack, TextField, Typography} from "@mui/material";
import {ButtonStack, TitleStack} from "../theme/standarts_styles";
import {useActions} from "../hook/useActions";
import {$authHost} from "../http";
import {acceptFileTypeBot, acceptFileTypeXL} from "../constants";
import AlertMessage from "../components/Alert/AlertMessage";
import FileKnowledgesTree from "../components/FileKnowledgesTree";
import {chekMaxSize} from "../utils/formatSize";
import {LoadingButton} from "@mui/lab";
import useResponsive from "../hook/useResponsive";

const KnowledgeManagement = ({visualChoice = false}) => {
  const {t} = useTranslation();
  const [openForm, setOpenForm] = useState(false);
  const [type, setType] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);
  const inputRef = useRef(null);
  const isMobile = useResponsive('down', 'md')

  const handleClickImport = () => {
    inputRef.current.click()
  }

  const {
    setTree,
    setSuccessAlertMessage,
    setErrorAlertMessage,
    setChosenNodes
  } = useActions()

  const addFile = async (file, data, parent, header) => {
    let timeStamp = new Date()
    setLoading(true)
    try {
      await $authHost.post(`/model_management/study_file/upload_file`, file, {
        'Content-Type': 'multipart/form-data',
        headers: {
          'File-Target': header || '',
        }
      }).then(async response => {
        if (response.status === 201) {
          let newData = {
            id: response.data,
            name: data.name,
            description: "",
            droppable: false,
            parent: parent,
            size: data.size,
            created_at: timeStamp
          }
          await $authHost.patch(`/model_management/study_file/update`, newData).then(response => {
            if (response.status === 201) {
              setOpenForm(false)
              setType(null)
              setUploadedFile(null)
              setLoading(false)
              loadData()
              setSuccessAlertMessage(t('src.components.Intelligence.importSuccess'))
            }
          })
        }
      })
    } catch (e) {
      if (e.response.status === 400 && e.response.data.detail === 'File not loading, there is an error: Worksheet named \'MS_CSP\' not found') {
        setErrorAlertMessage(t('src.components.Intelligence.importSheetTitleError'))
      } else {
        setErrorAlertMessage(t('src.components.Intelligence.importError'))
      }
      setUploadedFile(null);
      setOpenForm(false);
    }
  }

  const onDrop = async (e) => {
    const file = e.target.files && e.target.files[0];

    inputRef.current.value = null;

    if (acceptFileTypeBot.indexOf(file.type) !== -1) {
      if (chekMaxSize(file.size)) {
        // setOpenForm(true);
        setUploadedFile(file);
      } else {
        setErrorAlertMessage(t('src.components.Intelligence.limitationsSize'))
      }
    } else {
      setErrorAlertMessage(t('src.components.Intelligence.limitationsFile'))
    }
  }

  // const sendFile = () => {
  //   // if (type) {
  //     let bodyFormData = new FormData();
  //     bodyFormData.append('file', uploadedFile);
  //     addFile(bodyFormData, uploadedFile, 0, 'assistants');
  //   // }
  // }

  useEffect(() => {
    if(uploadedFile){
      let bodyFormData = new FormData();
      bodyFormData.append('file', uploadedFile);
      addFile(bodyFormData, uploadedFile, 0, 'assistants');
      // console.log(uploadedFile)
    }
  }, [uploadedFile]);

  const resetFile = () => {
    setOpenForm(false)
    setType(null)
    setUploadedFile(null)
  }

  const handleSubmit = async () => {
    let timeStamp = new Date()
    let data = {
      parent: "0",
      name: "untitled",
      droppable: true,
      description: "",
      created_at: timeStamp
    }
    try {
      await $authHost.post(`/model_management/study_file/upload_dir`, data).then(response => {
        if (response.status === 201) {
          loadData()
        }
      })
    } catch (e) {
    }

  };

  const loadData = useCallback(() => {
    try {
      const newChannelList = async () => {
        const response = await $authHost.get('/model_management/study_file/get_all');
        return response.data;
      }
      newChannelList().then((data) => {
        setTree(data);
      });
    } catch (e) {
    }
  }, []);

  return (
    <>
      <Container>
        {!visualChoice &&
          <>
            <TitleStack>
              <Typography variant="h4">
                {t('src.pages.KnowledgeManagement.header')}
              </Typography>
            </TitleStack>
            <ButtonStack>
              <input type="file"
                     accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                     onChange={onDrop} ref={inputRef} style={{display: "none"}}/>
              <Button
                onClick={handleSubmit}
                variant="contained"
                sx={{mr: 2}}
                size={isMobile ? 'small': 'medium'}
              >
                {t('src.pages.KnowledgeManagement.add_category')}
              </Button>
              <LoadingButton
                  variant="outlined"
                  onClick={handleClickImport}
                  loading={loading}
                  sx={{mr: 2}}
                  size={isMobile ? 'small': 'medium'}
              >
                {t('src.pages.KnowledgeManagement.add_file')}
              </LoadingButton>
            </ButtonStack>
            <AlertMessage/>
            {/*{openForm && (*/}
            {/*  <Card sx={{mb: 2, p: 3}}>*/}
            {/*    <Autocomplete*/}
            {/*      value={type}*/}
            {/*      onChange={(_, newValue) => {*/}
            {/*        setType(newValue)*/}
            {/*      }}*/}
            {/*      getOptionLabel={(option) => option.label}*/}
            {/*      isOptionEqualToValue={(option, v) => option.value === v.value}*/}
            {/*      options={[*/}
            {/*        {label: t('src.pages.KnowledgeManagement.dataAssistant'), value: 'assistants'},*/}
            {/*        {*/}
            {/*          label: t('src.pages.KnowledgeManagement.dataFineTuning'),*/}
            {/*          value: 'fine-tune'*/}
            {/*        }*/}
            {/*      ]}*/}
            {/*      size="small"*/}
            {/*      sx={{mb: 3, width: '100%'}}*/}
            {/*      renderInput={(params) =>*/}
            {/*        <TextField {...params} label={t('src.pages.KnowledgeManagement.dataType')} variant="outlined"*/}
            {/*                   InputLabelProps={{shrink: true}}/>*/}
            {/*      }*/}
            {/*    />*/}
            {/*    <Stack*/}
            {/*        direction="row"*/}
            {/*        justifyContent="space-between"*/}
            {/*        alignItems="center"*/}
            {/*    >*/}
            {/*      <LoadingButton*/}
            {/*          variant="contained"*/}
            {/*          disabled={!type}*/}
            {/*          onClick={sendFile}*/}
            {/*          loading={loading}*/}
            {/*      >*/}
            {/*        {t('src.pages.KnowledgeManagement.send')}*/}
            {/*      </LoadingButton>*/}
            {/*      <Button*/}
            {/*          variant="outlined"*/}
            {/*          onClick={resetFile}*/}
            {/*          disabled={loading}*/}
            {/*      >*/}
            {/*        {t('main.another.reset')}*/}
            {/*      </Button>*/}
            {/*    </Stack>*/}
            {/*  </Card>*/}
            {/*)}*/}
            <FileKnowledgesTree
              visualChoice={visualChoice}
              refTree={ref}
              loadData={loadData}
              canDrop={true}
              canDrag={true}
              addFile={() => {
              }}
              // addFile={addFile}
              loadMainData={() => {
              }}
              addFolder={handleSubmit}
            />
          </>
        }
      </Container>
    </>
  );
};

export default KnowledgeManagement;