import React, {useCallback, useEffect, useState} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Card,
  Divider, Fab, FormHelperText,
  Grid, IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import {Controller, FormProvider, useForm, useFormContext} from "react-hook-form";
import CustomTextField from "../Fields/CustomTextField";
import {useTranslation} from "react-i18next";
import Chip from "@mui/material/Chip";
import CustomTextFieldAutocomplete from "../Fields/CustomTextFieldAutocomplete";
import {useSelector} from "react-redux";
import {$authHost} from "../../http";
import {useActions} from "../../hook/useActions";
import {CustomDragPreview} from "../DnDTree/CustomDragPreview";
import CustomMultiTextFieldAutocomplete from "../Fields/CustomMultiTextFieldAutocomplete";
import AddIcon from "@mui/icons-material/Add";
import {useNavigate} from "react-router-dom";
import AlertMessage from "../Alert/AlertMessage";
import Alert from "@mui/material/Alert";
import LoaderTraining from "../loaderTraining";
import TrainingComponent from "../TrainingComponent";
import {LoadingButton} from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomTextFieldAutocompleteNotObj from "../Fields/CustomTextFieldAutocompleteNotObj";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Flow from "../DiagramComponent/Flow";
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import Iconify from "../iconify";
import i18next from "i18next";
import BlockFormModal from "../BlockFormModal";
import useResponsive from "../../hook/useResponsive";
import CloseIcon from "@mui/icons-material/Close";

const Settings = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const [studyBreakerLoad, setStudyBreakerLoad] = useState(false);
  const [studyOutputLoad, setStudyOutputLoad] = useState(false);
  const [defaultPrompts, setDefaultPrompts] = useState({});
  const [retrieverModels, setRetrieverModels] = useState([]);
  const [otherModels, setOtherModels] = useState([]);
  // const [typeForm, setTypeForm] = useState();
  const isMobile = useResponsive('down', 'md')



  const typeForm = window.location.pathname.split('/')


  const methods = useForm({
    mode: 'onBlur',
  })


  const {
    formState: {
      errors,
      isDirty,
      isSubmitSuccessful
    },
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    setError
  } = methods

  const {
    setChannels,
    setIntelligens,
    setSelectedIntellegence,
    setSuccessAlertMessage,
    setErrorAlertMessage,
    updateFineTuning,
    updateStudyStatus,
    setBreakerPrompt,
    setBouncerPrompt,
    setStylistPrompt,
    setRetrieverPrompt,
    setModifiedBreaker,
    setModifiedStylist,
    setModifiedRetriever,
    setModifiedBouncer,
    setModelTypeFormIsAdd
  } = useActions()

  const {
    modelTypeFormIsAdd,
    selectedIntellegence,
    studiedAssistants,
    modifiedBreaker,
    modifiedStylist,
    modifiedRetriever,
    modifiedBouncer
  } = useSelector(state => state.intelligensReducer)

  const {
    channels,
  } = useSelector(state => state.channelsReducer)

  const onSubmit = async (data) => {
    // setLoading(true)
    var pathArray = window.location.pathname.split('/')
    switch (pathArray[2]) {
      case 'new':
        // console.log("new", data)
        var new_data = {
          name: data.name,
          channels: data.channels,
          type: data.type,
          breaker_assistant: {
            type: "breaker",
            prompt: modifiedBreaker !== null ? modifiedBreaker : data.prompt_breaker,
            language: true,
            files: [],
            is_run: true,
            model_name: data.breaker_model_name
          },
          bouncer_assistant: {
            type: "bouncer",
            prompt: modifiedBouncer !== null ? modifiedBouncer : data.prompt_bouncer,
            language: true,
            files: [],
            is_run: true,
            model_name: data.bouncer_model_name
          },
          main_assistant: {
            type: "main",
            prompt: modifiedRetriever !== null ? modifiedRetriever : data.prompt_main,
            language: true,
            files: [],
            is_run: true,
            model_name: data.main_model_name
          },
          output_assistant: {
            type: "output",
            prompt: modifiedStylist !== null ? modifiedStylist : data.prompt_output,
            language: true,
            files: [],
            is_run: true,
            model_name: data.output_model_name
          },
          is_run: false
        }
        try {
          await $authHost.post(`/intellect/add_row`, new_data).then((response) => {
            if (response.status === 201) {
              // loadData(response.data);
              // props.loadMainData(selectedIntellegence.id);
              // navigate(`/model-management/edit/${response.data}`)
              // setSuccessAlertMessage(t('src.pages.ModelManagement.modelCreated'));

            }
          })
        } catch (e) {
          setError('root.serverError', {
            type: 'server',
            message: e.message,
          });
        } finally {
        }
        break
      case 'edit':
        var update_data = {
          id: selectedIntellegence.id,
          name: data.name,
          channels: data.channels,
          type: data.type,
          breaker_assistant: {
            id: selectedIntellegence.breaker_assistant.id,
            type: "breaker",
            prompt: modifiedBreaker !== null ? modifiedBreaker : data.prompt_breaker,
            language: true,
            files: [],
            is_run: true,
            model_name: data.breaker_model_name
          },
          bouncer_assistant: {
            id: selectedIntellegence.bouncer_assistant.id,
            type: "bouncer",
            prompt: modifiedBouncer !== null ? modifiedBouncer : data.prompt_bouncer,
            language: true,
            files: [],
            is_run: true,
            model_name: data.bouncer_model_name
          },
          main_assistant: {
            id: selectedIntellegence.main_assistant.id,
            type: "main",
            prompt: modifiedRetriever !== null ? modifiedRetriever : data.prompt_main,
            language: true,
            files: [],
            is_run: true,
            model_name: data.main_model_name
          },
          output_assistant: {
            id: selectedIntellegence.output_assistant.id,
            type: "output",
            prompt: modifiedStylist !== null ? modifiedStylist : data.prompt_output,
            language: true,
            files: [],
            is_run: true,
            model_name: data.output_model_name
          },
          is_run: false
        }
        try {
          await $authHost.patch(`/intellect/update_one`, update_data).then((response) => {
            if (response.status === 200) {
              // props.loadMainData(selectedIntellegence.id);
            }
          })
        } catch (e) {
          setError('root.serverError', {
            type: 'server',
            message: e.message,
          });
        } finally {
        }
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate('/intelligence');
    }
  }, [isSubmitSuccessful]);


  const loadData = useCallback(() => {
    try {
      const newchannelsList = async () => {
        const response = await $authHost.get('/channel/')
        return response.data
      }
      newchannelsList().then((data) => {
        setChannels(data)
      })
    } catch (e) {
    }

  }, [])

  const handleClose = () => {
    navigate('/intelligence');
  }

  const loadMainData = useCallback((selected = null) => {
    try {
      const newintellectsList = async () => {
        const response = await $authHost.get('/intellect/')
        return response.data
      }
      newintellectsList().then((data) => {
        setIntelligens(data)
        if (selected !== null) {
          setSelectedIntellegence(selected)
          loadStudyData(selectedIntellegence?.breaker_assistant.id)
          loadStudyData(selectedIntellegence?.output_assistant.id)
        }
      })
    } catch (e) {
    }
  }, [])

  const handleStudy = async (assistant_id, type) => {
    let st_data = {
      intellect_id: selectedIntellegence?.id,
      assistant_id: assistant_id
    }
    if (type === "breaker") {
      setStudyBreakerLoad(true)
    } else {
      setStudyOutputLoad(true);
    }
    try {
      await $authHost.post(`/model_management/fine_tuning`, st_data).then((response) => {
        if (response.status === 201) {
          if (type === "breaker") {
            setStudyBreakerLoad(false)
          } else {
            setStudyOutputLoad(false);
          }
          loadStudyData(response.data)
        }
      })
    } catch (e) {
    } finally {
    }
  }

  const cleanField = () => {
    setModifiedBouncer(null)
    setModifiedStylist(null)
    setModifiedRetriever(null)
    setModifiedBreaker(null)
    switch (modelTypeFormIsAdd) {
      case true:
        reset({
          name: '',
          channels: [],
          type: null,
          prompt_main: '',
          main_model_name: [],
          // language_main: '',
          prompt_breaker: '',
          breaker_model_name: [],
          prompt_bouncer: '',
          bouncer_model_name: [],
          // language_breaker: '',
          prompt_output: '',
          output_model_name: []
          // language_output: '',

        });
        break
      case false:
        setValue('name', selectedIntellegence.name)
        setValue('channels', selectedIntellegence.channels)
        setValue('type', selectedIntellegence.type)
        setValue('prompt_main', selectedIntellegence.main_assistant?.prompt)
        setValue('main_model_name', selectedIntellegence.main_assistant?.model_name)
        // setValue('language_main', selectedIntellegence.main_assistant.language)
        setValue('prompt_breaker', selectedIntellegence.breaker_assistant?.prompt)
        setValue('breaker_model_name', selectedIntellegence.breaker_assistant?.model_name)
        setValue('prompt_bouncer', selectedIntellegence.bouncer_assistant?.prompt)
        setValue('bouncer_model_name', selectedIntellegence.bouncer_assistant?.model_name)
        // setValue('language_breaker', selectedIntellegence.breaker_assistant.language)
        setValue('prompt_output', selectedIntellegence.output_assistant?.prompt)
        setValue('output_model_name', selectedIntellegence.output_assistant?.model_name)
        // setValue('language_output', selectedIntellegence.output_assistant.language)
        break
      default:
        break
    }
  }

  const loadStudyData = useCallback((id) => {

    try {
      const newintellectsList = async () => {
        const response = await $authHost.get(`/model_management/get_fine_tuning_history?assistant_id=${id}`)
        return response.data
      }
      newintellectsList().then((data) => {
        // console.log(data)
        updateStudyStatus(data)
      })
    } catch (e) {
    }
  }, [])

  const loadModelsData = useCallback(() => {
    try {
      const newModelsList = async () => {
        const response = await $authHost.get(`/model_management/models_list?is_retrieval=false`)
        return response.data
      }
      const newRetrieverModelsList = async () => {
        const response = await $authHost.get(`/model_management/models_list?is_retrieval=true`)
        return response.data
      }
      newModelsList().then((data) => {
        setOtherModels(data)
      })
      newRetrieverModelsList().then((data) => {
        setRetrieverModels(data)
      })
    } catch (e) {
    }
  }, [])

  useEffect(() => {
    var pathArray = window.location.pathname.split('/')
    loadData();
    loadModelsData();
    switch (pathArray[2]) {
      case 'new':
        setModelTypeFormIsAdd(true)
        loadMainData()
        break
      case 'edit':
        setModelTypeFormIsAdd(false)
        loadMainData(selectedIntellegence?.id);
        break
      default:
        break
    }
    cleanField()
  }, [])

  const getDefaultPrompts = async () => {
    try {
      const response = await $authHost.get(`/intellect/get_base_prompt`);
      setDefaultPrompts(response.data)
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getDefaultPrompts();
  }, [])

  useEffect(() => {
    const pathArray = window.location.pathname.split('/');
    if (pathArray[2] === 'new') {
      setValue('prompt_breaker', defaultPrompts.prompt_breaker || '');
      setValue('prompt_bouncer', defaultPrompts.prompt_bouncer || '');
      setValue('prompt_main', defaultPrompts.prompt_retriever || '');
      setValue('prompt_output', defaultPrompts.prompt_styler || '');
    }
  }, [defaultPrompts])

  const resetPrompt = (name) => {
    switch (name) {
      case 'breaker':
        setValue('prompt_breaker', defaultPrompts.prompt_breaker || '');
        break
      case 'bouncer':
        setValue('prompt_bouncer', defaultPrompts.prompt_bouncer || '');
        break
      case 'retriever':
        setValue('prompt_main', defaultPrompts.prompt_retriever || '');
        break
      case 'styler':
        setValue('prompt_output', defaultPrompts.prompt_styler || '');
        break
      default:
        break
    }
  }
  const [alignment, setAlignment] = React.useState('form');
  const handleAlignment = () => {
    if (alignment === "form") {
      setAlignment("blocks")
    } else {
      setAlignment("form")
    }
  };

  React.useEffect(() => {
    watch((value, {name, type}) => {
      setModifiedBreaker(value.prompt_breaker);
      setModifiedRetriever(value.prompt_main);
      setModifiedBouncer(value.prompt_bouncer);
      setModifiedStylist(value.prompt_output);
    })
  }, [watch])

  return (
    <Box
      sx={{flexDirection: 'column', px: 0, pt: 3, pb: 4, display: 'flex', alignItems: isMobile ? "flex-start" : "flex-end"}}
    >
      {
        isMobile ?
            <Fab
                color="primary"
                variant="extended"
                size='small'
                sx={{
                  mb: 2,
                  width: '100%'
                }}
                onClick={() => handleAlignment()}
            >
              {alignment === 'form' ? <AccountTreeIcon size="small" sx={{mr: 1}}/> :
                  <CalendarViewDayIcon size="small" sx={{mr: 1}}/>}
              {alignment === 'form' ? t('src.components.flow.switchButton_to_diagram') : t('src.components.flow.switchButton_to_form')}
            </Fab>
            :
            <Fab
                color="primary"
                variant="extended"
                size='large'
                sx={{
                  mr: 4,
                  mb: -3
                }}
                onClick={() => handleAlignment()}
            >
              {alignment === 'form' ? <AccountTreeIcon size="small" sx={{mr: 1}}/> :
                  <CalendarViewDayIcon size="small" sx={{mr: 1}}/>}
              {alignment === 'form' ? t('src.components.flow.switchButton_to_diagram') : t('src.components.flow.switchButton_to_form')}
            </Fab>
      }
      <Card sx={{px: isMobile ? 2 : 4, py: isMobile ? 2 : 3, width: '100%'}}>
        {alignment === 'form' ?
          <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {isMobile &&
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                  <Grid item>
                    <Typography variant='subtitle1' color="primary">
                      {modelTypeFormIsAdd ?
                          t('main.another.add')
                          :
                          t('main.another.edit')
                      }
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                        onClick={handleClose}
                        sx={{p: 0}}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
            }
            <Stack
              direction="column"
              // divider={<Divider/>}
              // spacing={2}
            >
              <Accordion sx={{width: '100%'}} defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  sx={{p: 0}}
                >
                  <Typography variant="headerTable" color="text.primary">
                    {t('src.pages.ModelManagement.commonSettings')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{p: 0}}>
                  <Grid container>
                    <Grid item xl={3} lg={3} md={3} sm={0} xs={0}>
                    </Grid>
                    <Grid
                      item
                      xl={9} lg={9} md={9} sm={12} xs={12}
                    >
                      <CustomTextField
                        name="name"
                        label={t('src.pages.ModelManagement.title')}
                        registerOptions={{
                          required: t('main.another.requiredField'),
                        }}
                        required
                        sx={{width: '100%', fontSize: '12px', mb: 3}}
                        size="small"
                      />
                      <CustomMultiTextFieldAutocomplete
                        multiple={true}
                        name="channels"
                        label={t('src.pages.ModelManagement.channels')}
                        options={channels}
                        sx={{width: '100%', fontSize: '12px', mb: 3}}
                        size="small"
                      />
                      <CustomTextFieldAutocomplete
                        name="type"
                        label={t('src.pages.ModelManagement.type')}
                        options={[{name: "Leksi", value: "Leksi"}, {name: "Albert", value: "Albert"}]}
                        rules={{
                          required: t('main.another.requiredField'),
                        }}
                        sx={{width: '100%', fontSize: '12px', mb: 3}}
                        size="small"
                        disabled={typeForm[2] !== 'new'}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{width: '100%'}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  sx={{p: 0}}
                >
                  <Iconify icon="icon-park-solid:block-one" width={25} sx={{color: "#fd5c25", mr: 1}}/>
                  <Typography variant="headerTable" color="text.primary">
                    {t('src.pages.Intelligence.breaker')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{p: 0}}>
                  <Grid container>
                    <Grid
                      item
                      xl={3} lg={3} md={3} sm={12} xs={12}
                      sx={{pr: isMobile ? 0 : 3, pb: isMobile ? 4 : 0}}
                    >
                      <Typography variant="subtitle2" color="text.secondary">
                        {t('src.pages.Intelligence.breakerInfo')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xl={9} lg={9} md={9} sm={12} xs={12}
                    >
                      {/*<CustomTextFieldAutocomplete*/}
                      {/*    name="language_breaker"*/}
                      {/*    label='Язык промпта'*/}
                      {/*    options={[{name:'Английский', value: true },{ name:'Русский', value: false }]}*/}
                      {/*    rules={{*/}
                      {/*        required: t('main.another.requiredField'),*/}
                      {/*    }}*/}
                      {/*    sx={{ width: '40%', fontSize: '12px', mb: 3 }}*/}
                      {/*    size="small"*/}
                      {/*/>*/}
                      <CustomTextField
                        name="prompt_breaker"
                        label={t('src.pages.ModelManagement.prompt')}
                        registerOptions={{
                          required: t('main.another.requiredField'),
                        }}
                        required
                        sx={{width: '100%', fontSize: '12px', mb: 3}}
                        size="small"
                        multiline={true}
                        rows={12}
                        value={modifiedBreaker !== null ? modifiedBreaker : selectedIntellegence?.breaker_assistant.prompt}
                        inputProps={{style: {resize: "vertical"}}}
                        helperText={t('src.pages.ModelManagement.helperText')}
                      />
                      <Button variant='outlined'
                              onClick={() => resetPrompt('breaker')}>{t('src.pages.ModelManagement.resetButton')}</Button>
                      <FormHelperText
                        sx={{ml: '14px', mb: 4}}>{t('src.pages.ModelManagement.resetHelperText')}</FormHelperText>
                      <CustomTextFieldAutocompleteNotObj
                        name="breaker_model_name"
                        label={t('src.pages.ModelManagement.gpt_models')}
                        registerOptions={{
                          required: t('main.another.requiredField'),
                        }}
                        required
                        // options={
                        //   [
                        //     "gpt-3.5-turbo",
                        //     "gpt-3.5-turbo-1106",
                        //     "gpt-4-1106-preview",
                        //     "gpt-4",
                        //     "gpt-4-32k"
                        //   ]
                        // }
                        options={otherModels && otherModels}
                        fullWidth
                        sx={{width: '100%', fontSize: '12px', mb: 2}}
                        size="small"
                      />
                      <TrainingComponent asist={selectedIntellegence?.breaker_assistant} buttondiv={
                        <Stack direction='row' justifyContent="flex-start" alignItems="center" spacing={2}>
                          <Typography variant="subtitle1">
                            {t('src.pages.ModelManagement.TrainedQuestions')}
                          </Typography>
                          <Chip
                            label={`${selectedIntellegence?.count_studied_breaker || 0}/${selectedIntellegence?.count_studied_breaker_all || 0}`}
                            color="primary"
                          />
                          <LoadingButton
                            onClick={() => handleStudy(selectedIntellegence?.breaker_assistant.id, "breaker")}
                            disabled={selectedIntellegence?.count_studied_breaker === selectedIntellegence?.count_studied_breaker_all}
                            loading={studyBreakerLoad}
                            loadingIndicator={t('src.components.Intelligence.Loading')}
                          >
                            {t('src.pages.ModelManagement.StartTraining')}
                          </LoadingButton>
                        </Stack>
                      }/>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{width: '100%'}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  sx={{p: 0}}
                >
                  <Iconify icon="icon-park-solid:block-six" width={25} sx={{color: "#1265ff", mr: 1}}/>
                  <Typography variant="headerTable" color="text.primary">
                    {t('src.pages.Intelligence.bouncer')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{p: 0}}>
                  <Grid container>
                    <Grid
                      item
                      xl={3} lg={3} md={3} sm={12} xs={12}
                      sx={{pr: isMobile ? 0 : 3, pb: isMobile ? 4 : 0}}
                    >
                      <Typography variant="subtitle2" color="text.secondary">
                        {t('src.pages.Intelligence.bouncerInfo')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xl={9} lg={9} md={9} sm={12} xs={12}
                    >
                      <CustomTextField
                        name="prompt_bouncer"
                        label={t('src.pages.ModelManagement.prompt')}
                        registerOptions={{
                          required: t('main.another.requiredField'),
                        }}
                        required
                        sx={{width: '100%', fontSize: '12px', mb: 3}}
                        size="small"
                        multiline={true}
                        rows={12}
                        value={modifiedBouncer !== null ? modifiedBouncer : selectedIntellegence?.bouncer_assistant.prompt}
                        inputProps={{style: {resize: "vertical"}}}
                        helperText={t('src.pages.ModelManagement.helperText')}
                      />
                      <Button variant='outlined'
                              onClick={() => resetPrompt('bouncer')}>{t('src.pages.ModelManagement.resetButton')}</Button>
                      <FormHelperText
                        sx={{ml: '14px', mb: 4}}>{t('src.pages.ModelManagement.resetHelperText')}</FormHelperText>
                      <CustomTextFieldAutocompleteNotObj
                        name="bouncer_model_name"
                        label={t('src.pages.ModelManagement.gpt_models')}
                        registerOptions={{
                          required: t('main.another.requiredField'),
                        }}
                        required
                        // options={
                        //   [
                        //     "gpt-3.5-turbo",
                        //     "gpt-3.5-turbo-1106",
                        //     "gpt-4-1106-preview",
                        //     "gpt-4",
                        //     "gpt-4-32k"
                        //   ]
                        // }
                        options={otherModels && otherModels}
                        fullWidth
                        sx={{width: '100%', fontSize: '12px', mb: 2}}
                        size="small"
                      />
                      <TrainingComponent asist={selectedIntellegence?.breaker_assistant} buttondiv={
                        <Stack direction='row' justifyContent="flex-start" alignItems="center" spacing={2}>
                          <Typography variant="subtitle1">
                            {t('src.pages.ModelManagement.TrainedQuestions')}
                          </Typography>
                          <Chip
                            label={`${selectedIntellegence?.count_studied_breaker || 0}/${selectedIntellegence?.count_studied_breaker_all || 0}`}
                            color="primary"
                          />
                          <LoadingButton
                            onClick={() => handleStudy(selectedIntellegence?.breaker_assistant.id, "breaker")}
                            disabled={selectedIntellegence?.count_studied_breaker === selectedIntellegence?.count_studied_breaker_all}
                            loading={studyBreakerLoad}
                            loadingIndicator={t('src.components.Intelligence.Loading')}
                          >
                            {t('src.pages.ModelManagement.StartTraining')}
                          </LoadingButton>
                        </Stack>
                      }/>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{width: '100%'}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  sx={{p: 0}}
                >
                  <Iconify icon="icon-park-solid:block-ten" width={25} sx={{color: "#b0ff60", mr: 1}}/>
                  <Typography variant="headerTable" color="text.primary">
                    {t('src.pages.Intelligence.retriever')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{p: 0}}>
                  <Grid
                    container
                  >
                    <Grid
                      item
                      xl={3} lg={3} md={3} sm={12} xs={12}
                      sx={{pr: isMobile ? 0 : 3, pb: isMobile ? 4 : 0}}
                    >
                      <Typography variant="subtitle2" color="text.secondary">
                        {t('src.pages.Intelligence.retrieverInfo')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xl={9} lg={9} md={9} sm={12} xs={12}
                    >
                      {/*<CustomTextFieldAutocomplete*/}
                      {/*    name="language_main"*/}
                      {/*    label='Язык промпта'*/}
                      {/*    options={[{name:'Английский', value: true },{ name:'Русский', value: false }]}*/}
                      {/*    rules={{*/}
                      {/*        required: t('main.another.requiredField'),*/}
                      {/*    }}*/}
                      {/*    sx={{ width: '40%', fontSize: '12px', mb: 3 }}*/}
                      {/*    size="small"*/}
                      {/*/>*/}
                      <CustomTextField
                        name="prompt_main"
                        label={t('src.pages.ModelManagement.prompt')}
                        registerOptions={{
                          required: t('main.another.requiredField'),
                        }}
                        required
                        sx={{width: '100%', fontSize: '12px', mb: 3}}
                        size="small"
                        value={modifiedRetriever !== null ? modifiedRetriever : selectedIntellegence?.main_assistant.prompt}
                        inputProps={{style: {resize: "vertical"}}}
                        helperText={t('src.pages.ModelManagement.helperText')}
                        multiline={true}
                        rows={12}
                      />
                      <Button variant='outlined'
                              onClick={() => resetPrompt('retriever')}>{t('src.pages.ModelManagement.resetButton')}</Button>
                      <FormHelperText
                        sx={{ml: '14px', mb: 4}}>{t('src.pages.ModelManagement.resetHelperText')}</FormHelperText>
                      <CustomTextFieldAutocompleteNotObj
                        name="main_model_name"
                        label={t('src.pages.ModelManagement.gpt_models')}
                        registerOptions={{
                          required: t('main.another.requiredField'),
                        }}
                        required
                        // options={
                        //   [
                        //     "gpt-3.5-turbo-1106",
                        //     "gpt-4-1106-preview",
                        //   ]
                        // }
                        options={retrieverModels && retrieverModels}
                        fullWidth
                        sx={{width: '100%', fontSize: '12px', mb: 2}}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{width: '100%'}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  sx={{p: 0}}
                >
                  <Iconify icon="icon-park-solid:block-two" width={25} sx={{color: "#5529ff", mr: 1}}/>
                  <Typography variant="headerTable" color="text.primary">
                    {t('src.pages.Intelligence.stylist')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{p: 0}}>
                  <Grid
                    container
                  >
                    <Grid
                      item
                      xl={3} lg={3} md={3} sm={12} xs={12}
                      sx={{pr: isMobile ? 0 : 3, pb: isMobile ? 4 : 0}}
                    >
                      <Typography variant="subtitle2" color="text.secondary">
                        {t('src.pages.Intelligence.stylistInfo')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xl={9} lg={9} md={9} sm={12} xs={12}
                    >
                      {/*<CustomTextFieldAutocomplete*/}
                      {/*    name="language_output"*/}
                      {/*    label='Язык промпта'*/}
                      {/*    options={[{name:'Английский', value: true },{ name:'Русский', value: false }]}*/}
                      {/*    rules={{*/}
                      {/*        required: t('main.another.requiredField'),*/}
                      {/*    }}*/}
                      {/*    sx={{ width: '40%', fontSize: '12px', mb: 3 }}*/}
                      {/*    size="small"*/}
                      {/*/>*/}
                      <CustomTextField
                        name="prompt_output"
                        label={t('src.pages.ModelManagement.prompt')}
                        registerOptions={{
                          // required: t('main.another.requiredField'),
                        }}
                        // required
                        sx={{width: '100%', fontSize: '12px', mb: 3}}
                        size="small"
                        multiline={true}
                        helperText={t('src.pages.ModelManagement.helperText')}
                        rows={12}
                        value={modifiedStylist !== null ? modifiedStylist : selectedIntellegence?.output_assistant.prompt}
                        inputProps={{style: {resize: "vertical"}}}
                      />
                      <Button variant='outlined'
                              onClick={() => resetPrompt('styler')}>{t('src.pages.ModelManagement.resetButton')}</Button>
                      <FormHelperText
                        sx={{ml: '14px', mb: 4}}>{t('src.pages.ModelManagement.resetHelperText')}</FormHelperText>
                      <CustomTextFieldAutocompleteNotObj
                        name="output_model_name"
                        label={t('src.pages.ModelManagement.gpt_models')}
                        registerOptions={{
                          required: t('main.another.requiredField'),
                        }}
                        required
                        // options={
                        //   [
                        //     "gpt-3.5-turbo",
                        //     "gpt-3.5-turbo-1106",
                        //     "gpt-4-1106-preview",
                        //     "gpt-4",
                        //     "gpt-4-32k"
                        //   ]
                        // }
                        options={otherModels && otherModels}
                        fullWidth
                        sx={{width: '100%', fontSize: '12px', mb: 2}}
                        size="small"
                      />
                      <TrainingComponent asist={selectedIntellegence?.output_assistant.id} buttondiv={
                        <Stack direction='row' justifyContent="flex-start" alignItems="center" spacing={2}>
                          <Typography variant="subtitle1">
                            {t('src.pages.ModelManagement.TrainedQuestions')}
                          </Typography>
                          <Chip
                            label={`${selectedIntellegence?.count_studied_output || 0}/${selectedIntellegence?.count_studied_output_all || 0}`}
                            color="primary"
                          />
                          <LoadingButton
                            onClick={() => handleStudy(selectedIntellegence?.output_assistant.id, "output")}
                            disabled={selectedIntellegence?.count_studied_output === selectedIntellegence?.count_studied_output_all}
                            loading={studyOutputLoad}
                            loadingIndicator={t('src.components.Intelligence.Loading')}
                          >
                            {t('src.pages.ModelManagement.StartTraining')}
                          </LoadingButton>
                        </Stack>
                      }/>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Grid>
                {isMobile ?
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        sx={{width: '100%', mt: 3}}
                    >
                      <Button
                          variant='contained'
                          type='submit'
                          sx={{width: '100%'}}
                          disabled={
                              selectedIntellegence?.output_assistant.status === "running" ||
                              selectedIntellegence?.breaker_assistant.status === "running" ||
                              selectedIntellegence?.output_assistant.status === "queued" ||
                              selectedIntellegence?.breaker_assistant.status === "queued" ||
                              selectedIntellegence?.output_assistant.status === "validating_files" ||
                              selectedIntellegence?.breaker_assistant.status === "validating_files"
                          }
                      >
                        {t('main.another.save')}
                      </Button>
                      <Button
                          variant="outlined"
                          disabled={
                              selectedIntellegence?.output_assistant.status === "running" ||
                              selectedIntellegence?.breaker_assistant.status === "running" ||
                              selectedIntellegence?.output_assistant.status === "queued" ||
                              selectedIntellegence?.breaker_assistant.status === "queued" ||
                              selectedIntellegence?.output_assistant.status === "validating_files" ||
                              selectedIntellegence?.breaker_assistant.status === "validating_files" ||
                              !isDirty
                          }
                          onClick={cleanField}
                          sx={{width: '100%'}}
                      >
                        {modelTypeFormIsAdd ? t('main.another.clear') : t('main.another.reset')}
                      </Button>
                    </Stack>
                    :
                    <Stack
                      direction='row'
                      spacing={1}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{mb: 2, mt: 2}}
                    >
                      <Grid item>
                        <Button
                          variant='contained'
                          type='submit'
                          sx={{mr: 2}}
                          disabled={
                            selectedIntellegence?.output_assistant.status === "running" ||
                            selectedIntellegence?.breaker_assistant.status === "running" ||
                            selectedIntellegence?.output_assistant.status === "queued" ||
                            selectedIntellegence?.breaker_assistant.status === "queued" ||
                            selectedIntellegence?.output_assistant.status === "validating_files" ||
                            selectedIntellegence?.breaker_assistant.status === "validating_files"
                          }
                        >
                          {t('main.another.save')}
                        </Button>
                        <Button variant="text" onClick={handleClose} sx={{ml:2}}>
                          {t('main.another.close')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant='outlined'
                          onClick={cleanField}
                          disabled={
                            selectedIntellegence?.output_assistant.status === "running" ||
                            selectedIntellegence?.breaker_assistant.status === "running" ||
                            selectedIntellegence?.output_assistant.status === "queued" ||
                            selectedIntellegence?.breaker_assistant.status === "queued" ||
                            selectedIntellegence?.output_assistant.status === "validating_files" ||
                            selectedIntellegence?.breaker_assistant.status === "validating_files" ||
                            !isDirty
                          }
                        >
                          {modelTypeFormIsAdd ? t('main.another.clear') : t('main.another.reset')}
                        </Button>
                      </Grid>
                    </Stack>
                }
                <AlertMessage/>
              </Grid>
            </Stack>
          </form>
        </FormProvider>
        :
        <Flow/>
        }
      </Card>
      <BlockFormModal when={isDirty && !isSubmitSuccessful}/>
    </Box>
  );
};

export default Settings;